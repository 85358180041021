import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import router from './router/index';
import store from './store/store'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import './main.css';
import FontAwesomeIcon from './components/fontawesomeicons';
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import Pagination from './components/Pagination.vue';
import SkeletonLoader from './components/SkeletonLoader.vue';

import PrintButtonHeader from './components/PrintButtonHeader.vue';

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VNum from "v3-num";
// import { isSubscribed } from './isSubscribed';
import Flutterwave from 'flutterwave-vue3'

// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';
// export default {
//     hostName: 'http://127.0.0.1:8000/'
// }

axios.defaults.baseURL = 'https://pos.onebmac.com/api/';
export default {
    hostName : 'https://pos.onebmac.com/'
}

// axios.defaults.baseURL = 'https://test.pos.onebmac.com/api/';
// export default {
//     hostName : 'https://test.pos.onebmac.com/'
// }


const options = {
    "timeout": 1900
};

axios.defaults.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
const app = createApp(App)



app.use(router).use(store).use(VNum).use(Flutterwave).use(Toast, options).use(VueLoading, { color: 'red' }, {}).component("font-awesome-icon", FontAwesomeIcon).component("Pagination", Pagination).component("PrintButtonHeader", PrintButtonHeader)
    .component("SkeletonLoader", SkeletonLoader)
    .component('Datepicker', Datepicker).use(VueTelInput).mount('#app')


app.config.globalProperties.$filters = {
    formatMoney(n, currency = '') {
        return currency + parseFloat(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');

    }
}



router.beforeEach((to, from, next) => {
    let currentUser = store.state.getLoggedInUser
    if (to.matched.some(record => record.meta.requiresSubscription) && !currentUser?.has_subscribed) {
        // next({ name: "Subscribe" })
        next();

        // isSubscribed.access(to, next, currentUser)
    } else {
        next();
    }
})
